@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';

/* Rich text editor */
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';

/* Datetime picker */
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";

/* ACE EDITOR */
.ace_editor, .ace_editor *{
    font-family: "Jetbrains Mono", "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas", monospace !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
}

/* ANIMATIONS */
.fadeIn {
    animation: 0.5s fadeIn forwards;
}

.fadeOut {
    animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* PRETTY STUFF */
body {
    scroll-behavior: smooth;
    /* Set a repeating bg image with opacity 0.1 from the resosurces/bg.png image */
    background-image: url("resources/bg.png") !important;
    background-repeat: repeat !important;
}
::-webkit-scrollbar {
    display: none;
    width: none;
  }

* {
    font-weight: 300;
}

h1 {
    font-weight: 700;
    font-size: xx-large;
    margin-bottom: 20px;
}

h2 {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: x-large;
}

h3 {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: large;
}

.text-fragment h1 strong {
    font-weight: 800;
}
.text-fragment h2 strong {
    font-weight: 700;
}
.text-fragment h3 strong {
    font-weight: 700;
}

.text-fragment p {
    margin-bottom: 10px;
}

.text-fragment a {
    color: #007bff;
    text-decoration: none;
    font-weight: 600;
}

strong {
    font-weight: 600;
}

.text-fragment ol, ul {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    margin-left: 30px !important;
}

button {
    font-weight: 100;
}

.playbutton:hover {
    filter: brightness(80%);
}

.code-inline, pre {
    font-size: 15px;
    line-height: 1.73em;
    padding: 4px 8px;
    border: 1pt solid white;
    background: #eeeeee;
    font-family: "Jetbrains Mono",monospace;
    font-weight: 500;
    color: #000;
}

.highlight-inline {
    font-size: 15px;
    line-height: 1.73em;
    padding: 5px 10px;
    background: #f5e9a6;;
    font-weight: 500;
    color: #000;
}

.highlight-block {
    font-size: 15px;
    line-height: 1.73em;
    padding: 25px;
    margin-bottom: 0px;
    margin-top: 10px;
    /* box-shadow: 0px 0px 10px 0px rgba(255,0,0,0.1); */
    background: #faf0bb;;
    /* font-weight: 500; */
    color: #000;
}

.link {
    font-weight:'medium';
    color: 'blue.500 !important';
}